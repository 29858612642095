<template>
  <div>
    <span v-if="!axiosLoading && !$vuetify.breakpoint.smAndDown " class="text-smaller">{{pageInfo}}</span>
    <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          :disabled="filterParams.currentPage <= 1"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="changePage(-1)"
        >
          <v-icon>{{ iconLeft }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.previous-page") }}</span>
    </v-tooltip>

    <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          :disabled="filterParams.currentPage >= filterParams.lastPage"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="changePage(1)"
        >
          <v-icon>{{ iconRight }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.next-page") }}</span>
    </v-tooltip>

    <v-menu
      v-if="$vuetify.breakpoint.smAndDown && !enableAction"
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="blue lighten-2 ml-3" icon v-bind="attrs" v-on="on">
          <v-icon>{{ iconAction }}</v-icon>
        </v-btn>
      </template>

      <v-list>
        <div v-for="item in mobileSortMenu" :key="item.id">
          <v-list-item
            v-if="item.slug"
            class="cursor-pointer memo-header-menu"
            @click="runCommand(item.command, item.slug)"
            :class="{
              'v-list-item--active success white--text':
                filterParams.sortByDesc == item.slug,
            }"
          >
            <v-icon class="mr-3">{{ item.icon }}</v-icon>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item>
          <v-divider v-else :key="item.id"></v-divider>
        </div>
      </v-list>
    </v-menu>

    <v-menu
      v-if="false"
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="blue lighten-2 ml-3" icon v-bind="attrs" v-on="on">
          <v-icon>{{ iconAction }}</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in mobileActionMenu"
          :key="i"
          class="cursor-pointer memo-header-menu"
          @click="runCommand(item.command, item.slug)"
          :class="{
            'v-list-item--active success white--text':
              filterParams.sortByDesc == item.slug,
          }"
        >
          <v-icon class="mr-3">{{ item.icon }}</v-icon>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu bottom left v-if="!$vuetify.breakpoint.smAndDown">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              color="blue lighten-2 ml-3"
            >
              <v-icon>{{ iconSort }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.sort-data") }}</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in menus"
          :key="i"
          class="cursor-pointer memo-header-menu"
          @click="orderDesc(item.slug)"
          :class="{
            'v-list-item--active success white--text':
              filterParams.sortByDesc == item.slug,
          }"
        >
          <v-icon class="mr-3">{{ item.icon }}</v-icon>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  mdiMenu,
  mdiCheckCircleOutline,
  mdiSort,
  mdiArrowLeftThick,
  mdiArrowRightThick,
  mdiDotsVertical,
} from "@mdi/js";
import toolbarMenu from "@/constants/toolbarmenu";

export default {
  name: "header-right",
  props: {
    enableAction: {
      type: Boolean,
    },
  },
  data: () => ({
    iconMenu: mdiMenu,
    iconAction: mdiDotsVertical,
    iconCheck: mdiCheckCircleOutline,
    iconSort: mdiSort,
    iconLeft: mdiArrowLeftThick,
    iconRight: mdiArrowRightThick,
    menus: toolbarMenu.inboxSortMenu,
    mobileSortMenu: toolbarMenu.inboxMobileSortMenu,
    mobileActionMenu: toolbarMenu.inboxMobileActionMenu,
  }),
  computed: {
    ...mapState("inbox", ["filterParams", "axiosLoading"]),
    pageInfo(){
      let params = this.filterParams;
      if (!params.form && !params.to) return '';
      return params.from + '-'+ params.to + ' '+ this.$t('text.from') +' '+params.total;
    }
  },
  methods: {
    ...mapActions("inbox", ["setFilterParams"]),
    changePage(val) {
      let newVal = this.filterParams.currentPage + val;

      if (newVal < 1) newVal = 1;
      if (newVal > this.filterParams.lastPage)
        newVal = this.filterParams.lastPage;

      if (newVal == this.filterParams.currentPage) return;

      let params = this.filterParams;
      params.currentPage = newVal;

      this.inboxReload();
    },
    runCommand(command, slug) {
      // console.log('command');
      if (command == "change_page") {
        this.changePage(slug);
      } else if (command == "sort_data") {
        this.orderDesc(slug);
      }
      else if (command =='filter_data'){
        this.$emit("menuAction", { command: command, slug: slug });
      }
    },
    inboxReload() {
      this.$emit("inboxReload");
    },
   orderDesc(val) {
      if (val == this.filterParams.sortByDesc) return;
      let params = this.filterParams;
      params.sortByDesc = val;

      this.inboxReload();
    },
  },
};
</script>